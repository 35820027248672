// Solid Icons
import {
  AdjustmentsVerticalIcon as solidAdjustmentsVerticalIcon,
  ArchiveBoxIcon as solidArchiveBoxIcon,
  ArrowTopRightOnSquareIcon as solidArrowTopRightOnSquareIcon,
  ArrowDownIcon as solidArrowDownIcon,
  ArrowDownOnSquareIcon as solidArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon as solidArrowLeftOnRectangleIcon,
  ArrowLeftIcon as solidArrowLeftIcon,
  ArrowPathIcon as solidArrowPathIcon,
  ArrowRightIcon as solidArrowRightIcon,
  ArrowRightOnRectangleIcon as solidArrowRightOnRectangleIcon,
  ArrowLongUpIcon as solidArrowLongUpIcon,
  ArrowLongDownIcon as solidArrowLongDownIcon,
  ArrowUturnLeftIcon as solidArrowUturnLeftIcon,
  Bars3Icon as solidBars3Icon,
  BellIcon as solidBellIcon,
  BoltSlashIcon as solidBoltSlashIcon,
  BookmarkIcon as solidBookmarkIcon,
  BuildingStorefrontIcon as solidBuildingStorefrontIcon,
  StopIcon as solidStopIcon,
  BriefcaseIcon as solidBriefcaseIcon,
  CreditCardIcon as solidCreditCardIcon,
  CheckIcon as solidCheckIcon,
  CheckCircleIcon as solidCheckCircleIcon,
  ChartPieIcon as solidChartPieIcon,
  ChevronDownIcon as solidChevronDownIcon,
  ChevronUpIcon as solidChevronUpIcon,
  ChevronLeftIcon as solidChevronLeftIcon,
  ClockIcon as solidClockIcon,
  ComputerDesktopIcon as solidComputerDesktopIcon,
  Cog8ToothIcon as solidCog8ToothIcon,
  CurrencyDollarIcon as solidCurrencyDollarIcon,
  CubeIcon as solidCubeIcon,
  ChartBarIcon as solidChartBarIcon,
  DevicePhoneMobileIcon as solidDevicePhoneMobileIcon,
  EyeIcon as solidEyeIcon,
  ExclamationTriangleIcon as solidExclamationTriangleIcon,
  EllipsisHorizontalIcon as solidEllipsisHorizontalIcon,
  EllipsisVerticalIcon as solidEllipsisVerticalIcon,
  FunnelIcon as solidFunnelIcon,
  GiftIcon as solidGiftIcon,
  GiftTopIcon as solidGiftTopIcon,
  InformationCircleIcon as solidInformationCircleIcon,
  KeyIcon as solidKeyIcon,
  LockClosedIcon as solidLockClosedIcon,
  LockOpenIcon as solidLockOpenIcon,
  LinkIcon as solidLinkIcon,
  MinusIcon as solidMinusIcon,
  NoSymbolIcon as solidNoSymbolIcon,
  PencilIcon as solidPencilIcon,
  PencilSquareIcon as solidPencilSquareIcon,
  PlusIcon as solidPlusIcon,
  PowerIcon as solidPowerIcon,
  PrinterIcon as solidPrinterIcon,
  QuestionMarkCircleIcon as solidQuestionMarkCircleIcon,
  ReceiptPercentIcon as solidReceiptPercentIcon,
  MagnifyingGlassIcon as solidMagnifyingGlassIcon,
  MapPinIcon as solidMapPinIcon,
  RectangleStackIcon as solidRectangleStackIcon,
  ShieldCheckIcon as solidShieldCheckIcon,
  ShoppingCartIcon as solidShoppingCartIcon,
  ShoppingBagIcon as solidShoppingBagIcon,
  StarIcon as solidStarIcon,
  Square3Stack3DIcon as solidSquare3Stack3DIcon,
  TableCellsIcon as solidTableCellsIcon,
  TrashIcon as solidTrashIcon,
  TicketIcon as solidTicketIcon,
  TruckIcon as solidTruckIcon,
  UserCircleIcon as solidUserCircleIcon,
  UserIcon as solidUserIcon,
  UserGroupIcon as solidUserGroupIcon,
  UsersIcon as solidUsersIcon,
  WrenchScrewdriverIcon as solidWrenchScrewdriverIcon,
  XMarkIcon as solidXMarkIcon,
  XCircleIcon as solidXCircleIcon,
} from "@heroicons/vue/24/solid";
// Outline Icons
import {
  AdjustmentsVerticalIcon as outlineAdjustmentsVerticalIcon,
  ArchiveBoxIcon as outlineArchiveBoxIcon,
  ArrowTopRightOnSquareIcon as outlineArrowTopRightOnSquareIcon,
  ArrowDownIcon as outlineArrowDownIcon,
  ArrowDownOnSquareIcon as outlineArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon as outlineArrowLeftOnRectangleIcon,
  ArrowLeftIcon as outlineArrowLeftIcon,
  ArrowPathIcon as outlineArrowPathIcon,
  ArrowRightIcon as outlineArrowRightIcon,
  ArrowRightOnRectangleIcon as outlineArrowRightOnRectangleIcon,
  ArrowLongUpIcon as outlineArrowLongUpIcon,
  ArrowLongDownIcon as outlineArrowLongDownIcon,
  ArrowUturnLeftIcon as outlineArrowUturnLeftIcon,
  Bars3Icon as outlineBars3Icon,
  BellIcon as outlineBellIcon,
  BoltSlashIcon as outlineBoltSlashIcon,
  BookmarkIcon as outlineBookmarkIcon,
  BriefcaseIcon as outlineBriefcaseIcon,
  BuildingStorefrontIcon as outlineBuildingStorefrontIcon,
  CreditCardIcon as outlineCreditCardIcon,
  CheckIcon as outlineCheckIcon,
  CheckCircleIcon as outlineCheckCircleIcon,
  ChartPieIcon as outlineChartPieIcon,
  ChevronDownIcon as outlineChevronDownIcon,
  ChevronUpIcon as outlineChevronUpIcon,
  ChevronLeftIcon as outlineChevronLeftIcon,
  ChartBarIcon as outlineChartBarIcon,
  ClockIcon as outlineClockIcon,
  ComputerDesktopIcon as outlineComputerDesktopIcon,
  CurrencyDollarIcon as outlineCurrencyDollarIcon,
  CubeIcon as outlineCubeIcon,
  Cog8ToothIcon as outlineCog8ToothIcon,
  DevicePhoneMobileIcon as outlineDevicePhoneMobileIcon,
  ExclamationTriangleIcon as outlineExclamationTriangleIcon,
  EllipsisHorizontalIcon as outlineEllipsisHorizontalIcon,
  EllipsisVerticalIcon as outlineEllipsisVerticalIcon,
  EyeIcon as outlineEyeIcon,
  FunnelIcon as outlineFunnelIcon,
  GiftTopIcon as outlineGiftTopIcon,
  GiftIcon as outlineGiftIcon,
  InformationCircleIcon as outlineInformationCircleIcon,
  KeyIcon as outlineKeyIcon,
  LockClosedIcon as outlineLockClosedIcon,
  LockOpenIcon as outlineLockOpenIcon,
  LinkIcon as outlineLinkIcon,
  MinusIcon as outlineMinusIcon,
  MagnifyingGlassIcon as outlineMagnifyingGlassIcon,
  MapPinIcon as outlineMapPinIcon,
  NoSymbolIcon as outlineNoSymbolIcon,
  PencilIcon as outlinePencilIcon,
  PencilSquareIcon as outlinePencilSquareIcon,
  PlusIcon as outlinePlusIcon,
  PowerIcon as outlinePowerIcon,
  PrinterIcon as outlinePrinterIcon,
  QuestionMarkCircleIcon as outlineQuestionMarkCircleIcon,
  ReceiptPercentIcon as outlineReceiptPercentIcon,
  RectangleStackIcon as outlineRectangleStackIcon,
  ShieldCheckIcon as outlineShieldCheckIcon,
  ShoppingCartIcon as outlineShoppingCartIcon,
  ShoppingBagIcon as outlineShoppingBagIcon,
  StopIcon as outlineStopIcon,
  StarIcon as outlineStarIcon,
  Square3Stack3DIcon as outlineSquare3Stack3DIcon,
  TrashIcon as outlineTrashIcon,
  TableCellsIcon as outlineTableCellsIcon,
  TicketIcon as outlineTicketIcon,
  TruckIcon as outlineTruckIcon,
  UserCircleIcon as outlineUserCircleIcon,
  UserIcon as outlineUserIcon,
  UserGroupIcon as outlineUserGroupIcon,
  UsersIcon as outlineUsersIcon,
  WrenchScrewdriverIcon as outlineWrenchScrewdriverIcon,
  XMarkIcon as outlineXMarkIcon,
  XCircleIcon as outlineXCircleIcon,
} from "@heroicons/vue/24/outline";

export const iconsSet = Object.assign(
  {},
  {
    // Solid Icons
    solidAdjustmentsVerticalIcon,
    solidArchiveBoxIcon,
    solidArrowTopRightOnSquareIcon,
    solidArrowDownIcon,
    solidArrowDownOnSquareIcon,
    solidArrowLeftOnRectangleIcon,
    solidArrowLeftIcon,
    solidArrowPathIcon,
    solidArrowRightIcon,
    solidArrowRightOnRectangleIcon,
    solidArrowLongUpIcon,
    solidArrowLongDownIcon,
    solidArrowUturnLeftIcon,
    solidBars3Icon,
    solidBellIcon,
    solidBoltSlashIcon,
    solidBookmarkIcon,
    solidBriefcaseIcon,
    solidBuildingStorefrontIcon,
    solidCreditCardIcon,
    solidChartPieIcon,
    solidChartBarIcon,
    solidCheckIcon,
    solidCheckCircleIcon,
    solidChevronDownIcon,
    solidChevronUpIcon,
    solidChevronLeftIcon,
    solidClockIcon,
    solidComputerDesktopIcon,
    solidCog8ToothIcon,
    solidCubeIcon,
    solidCurrencyDollarIcon,
    solidDevicePhoneMobileIcon,
    solidEllipsisHorizontalIcon,
    solidEllipsisVerticalIcon,
    solidFunnelIcon,
    solidGiftIcon,
    solidGiftTopIcon,
    solidExclamationTriangleIcon,
    solidEyeIcon,
    solidInformationCircleIcon,
    solidKeyIcon,
    solidLockClosedIcon,
    solidLockOpenIcon,
    solidLinkIcon,
    solidMagnifyingGlassIcon,
    solidMapPinIcon,
    solidMinusIcon,
    solidNoSymbolIcon,
    solidPencilIcon,
    solidPencilSquareIcon,
    solidPlusIcon,
    solidPowerIcon,
    solidPrinterIcon,
    solidQuestionMarkCircleIcon,
    solidRectangleStackIcon,
    solidReceiptPercentIcon,
    solidShieldCheckIcon,
    solidShoppingCartIcon,
    solidShoppingBagIcon,
    solidStarIcon,
    solidStopIcon,
    solidSquare3Stack3DIcon,
    solidTrashIcon,
    solidTableCellsIcon,
    solidTicketIcon,
    solidTruckIcon,
    solidUserCircleIcon,
    solidUserIcon,
    solidUserGroupIcon,
    solidUsersIcon,
    solidWrenchScrewdriverIcon,
    solidXMarkIcon,
    solidXCircleIcon,
    // Outline Icons
    outlineAdjustmentsVerticalIcon,
    outlineArchiveBoxIcon,
    outlineArrowTopRightOnSquareIcon,
    outlineArrowDownIcon,
    outlineArrowDownOnSquareIcon,
    outlineArrowLeftOnRectangleIcon,
    outlineArrowLeftIcon,
    outlineArrowPathIcon,
    outlineArrowRightIcon,
    outlineArrowRightOnRectangleIcon,
    outlineArrowLongUpIcon,
    outlineArrowLongDownIcon,
    outlineArrowUturnLeftIcon,
    outlineBars3Icon,
    outlineBellIcon,
    outlineBoltSlashIcon,
    outlineBookmarkIcon,
    outlineBriefcaseIcon,
    outlineBuildingStorefrontIcon,
    outlineCreditCardIcon,
    outlineChartBarIcon,
    outlineChartPieIcon,
    outlineCheckIcon,
    outlineCheckCircleIcon,
    outlineChevronDownIcon,
    outlineChevronUpIcon,
    outlineChevronLeftIcon,
    outlineClockIcon,
    outlineComputerDesktopIcon,
    outlineCog8ToothIcon,
    outlineCubeIcon,
    outlineCurrencyDollarIcon,
    outlineDevicePhoneMobileIcon,
    outlineExclamationTriangleIcon,
    outlineEyeIcon,
    outlineEllipsisHorizontalIcon,
    outlineEllipsisVerticalIcon,
    outlineFunnelIcon,
    outlineGiftIcon,
    outlineGiftTopIcon,
    outlineInformationCircleIcon,
    outlineKeyIcon,
    outlineLockClosedIcon,
    outlineLockOpenIcon,
    outlineLinkIcon,
    outlineMagnifyingGlassIcon,
    outlineMapPinIcon,
    outlineMinusIcon,
    outlineNoSymbolIcon,
    outlinePencilIcon,
    outlineQuestionMarkCircleIcon,
    outlinePencilSquareIcon,
    outlinePlusIcon,
    outlinePowerIcon,
    outlinePrinterIcon,
    outlineReceiptPercentIcon,
    outlineRectangleStackIcon,
    outlineShieldCheckIcon,
    outlineShoppingCartIcon,
    outlineShoppingBagIcon,
    outlineStarIcon,
    outlineStopIcon,
    outlineSquare3Stack3DIcon,
    outlineTrashIcon,
    outlineTableCellsIcon,
    outlineTicketIcon,
    outlineTruckIcon,
    outlineUserCircleIcon,
    outlineUserIcon,
    outlineUserGroupIcon,
    outlineUsersIcon,
    outlineWrenchScrewdriverIcon,
    outlineXMarkIcon,
    outlineXCircleIcon,
  }
);
